import "./contact.scss"
import {Instagram, LinkedIn, LocationOnOutlined, MailOutline, PermPhoneMsgOutlined} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {useMatch,Link} from "react-router-dom";

export default function Contact() {

    return <div className="contact-root">
        <div className="title">About</div>
        <div className="content">
            {/*<div className="l">*/}
            {/*</div>*/}
            <div  className={"l"}>
            <img  src={require("./assets/photo.jpg")} alt="bg"/></div>
            <div className="r">
                <div className={"title"}>Josh Yiheng Huang</div>
                <div style={{marginBottom:"25px"}}>Concept Art & Design</div>
                <div><MailOutline/> JoshPreslion@gmail.com</div>
                <div><PermPhoneMsgOutlined/> 912-503-6286</div>
                <div><LocationOnOutlined/> GA, US</div>
                <div className={"socials"}>
                    <IconButton color="primary" aria-label="linkedin"
                                href="https://www.linkedin.com/in/josh-huang-17630822b/" target="_blank">
                        <LinkedIn />
                    </IconButton>
                    <IconButton color="primary" aria-label="artStation"
                                href="https://www.artstation.com/preslion" target="_blank">
                        <img src={require("./assets/artstation_icon.png")} alt={"artStation"}/>
                    </IconButton>
                    <IconButton color="primary" aria-label="instagram"
                                href="https://www.instagram.com/preslion/" target="_blank">
                        <Instagram />
                    </IconButton>
                    <Link className={"resume"} to={`/resume`}>Resume</Link>
                </div>

                <div className={"story"}>
                    Josh Huang is a concept artist specializing in environment and props design for video games,
                    animation
                    and film industry.
                    He is proficient in using various 2d and 3d tools to create design pieces with unique style.
                    He is an experienced team player that works double hard with colleagues to achieve the design goal.
                </div>
            </div>
        </div>
        <a  href="mailto:JoshPreslion@gmail.com" className={"hire"}><MailOutline/>HIRE ME</a>
    </div>
}
export default function images() {
    return [
        "radio tower",
        "power generation facility",
        "portals to the future",
        "fish moving co",
        "Jem and the Hologram",
        "hologram mansion",
        "into a nightmare",
        "temple of worship",
        "katana angel",
        "look at what we built",
        "beetle warrior",
        "moth mage",
        "vk-3 chivalry standard vehicle",
        "habitat field research",
        "house of three",
        "raising a baby",
        "baby chamber",
    ]
}
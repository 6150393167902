import images from "./images";
import "./works.scss"
import {Modal} from "@mui/material";
import {useEffect, useState} from "react";
import {ArrowBackIosNew, ArrowForwardIos} from "@mui/icons-material";

export default function Works() {
    const [open, setOpen] = useState(false);
    const [viewing, setViewing] = useState(0)
    const clamp = (index: number) => {
        if(index<0){
            console.log("<0")
            return images().length - 1
        }else if (index > images().length - 1){
            console.log(" > images().length - 1")
            return 0
        }else{
            console.log(" else")
            return index
        }
    }

    //'ArrowLeft'← 'ArrowRight'→
    // const ArrowFlip = (key:number) => {
    //
    // }
    // useEffect(() => {
    //     window.addEventListener('keydown', (e) => {
    //         console.log(e.code)
    //         if (e.code === 'ArrowRight') {
    //             setViewing((viewing + 1))
    //         } else if (e.code === 'ArrowLeft') {
    //             setViewing(clamp(viewing - 1))
    //         }
    //         if (open){
    //         }
    //     });
    // }, []);

    const handleOpen = (imageIndex: number) => {
        setOpen(true)
        setViewing(imageIndex)
    };
    const handleClose = () => setOpen(false);

    return <div className={"works-root"}>
        <div className={"intro"}>- Concept Arts -</div>
        <div className="images">
            {images().map((image: string, index: number) =>
                <div className="image-container" onClick={() => handleOpen(index)}>
                    <div className="backdrop">
                        <div className="name">{image}</div>
                    </div>
                    <img src={require(`./assets/${image.replace(/ /g, ' ')}.jpg`)}
                         alt={image} loading="lazy"/>
                </div>
            )}</div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={"modal-content"} onClick={handleClose}>
                <img
                    src={require(`./assets/${images()[viewing].replace(/ /g, ' ')}.jpg`)}
                    alt={images()[viewing]}/>
                <div className="name text">{images()[viewing]}</div>
                <div className="arrows">
                    <div className="l arrow" onClick={(e) => {
                        e.stopPropagation();
                        setViewing(clamp(viewing - 1))
                    }}><ArrowBackIosNew/></div>
                    <div className="r arrow" onClick={(e) => {
                        e.stopPropagation();
                        setViewing(clamp(viewing + 1))
                    }}><ArrowForwardIos/></div>
                </div>
            </div>
        </Modal>
    </div>
}
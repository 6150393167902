import React from 'react';
import logo from './assets/logo.png';
import logo2 from './assets/logo2.png';
import './App.scss';
import {Route, Routes, Navigate, useMatch, Link, useNavigate} from "react-router-dom"
import Works from "./works";
import Resume from "./resume";
import Contact from "./contact";

function App() {
    const navigate = useNavigate();
    return (
        <div className="App">
            <div className="header">
                <div className="l" onClick={()=>navigate(`/`)}>
                    <img src={logo} alt={"logo"} className="logo"/>
                    <img src={logo2} alt={"logo2"} className="logo2"/>
                </div>
                <div className="r">
                    <Link to={`/works`} className={useMatch("/works") ? "active" : ""}>Works</Link>
                    {/*<Link to={`/games`} className={useMatch("/games") ? "active" : ""}>Games</Link>*/}
                    {/*<Link to={`/resume`} className={useMatch("/resume") ? "active" : ""}>Resume</Link>*/}
                    <Link to={`/about`} className={useMatch("/about") ? "active" : ""}>About</Link>
                </div>
            </div>
            <div className="main_container">
                <Routes>
                    <Route index element={<Navigate to={`/works`}/>}/>
                    <Route path='/works/*' element={<Works/>}/>
                    <Route path="/games/*" element={<div>3</div>}/>
                    <Route path="/resume/*" element={<Resume/>}/>
                    <Route path="/about/*" element={<Contact/>}/>
                </Routes>
            </div>
            <div className="footer">
                <div>joshpreslion@gmail.com</div>
                <div className={"grey"}>Josh Huang. all rights reserved.</div>
            </div>

        </div>
    );
}

export default App;
